import React from "react";
import { Container } from "react-bootstrap";
import bitcoin from "../../images/bitcoin.svg";
import returnFundsData from "../data/returnFunds";
const ReturnFunds = () => {
  const returnFunds = returnFundsData();
  return (
    <section className="bg-dark py-5 returnFunds" id="return-funds">
      {/* <img src={returnBg} alt="section background"/> */}
      <Container className="pt-2 pb-5">
        <div className="returnFunds__inner">
          <div className="returnFundsIMG">
            <img src={bitcoin} alt="" />
          </div>
          <div className="textBlock">
            <h3 className="my-4">{returnFunds.title}</h3>
            <p>{returnFunds.desc}</p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ReturnFunds;
