import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import benefitsData from "../data/benefitsData";

const Benefits = () => {
  const bens = benefitsData();
  return (
    <section className="bg-dark benefits py-5" id="benefits">
      <Container className="pt-2 pb-5">
        <div className="title"><h3>{bens.title}</h3></div>
        <Row>
          {bens.list.map((e, i) => (
            <Col md="6" xl="3" key={i} className="benefitsItem">
              <div className="benefitsItemImg" dangerouslySetInnerHTML={{ __html: e.icon }}></div>
              <div className="textBlock">
                <h4>{e.title}</h4>
                <p>{e.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Benefits;
