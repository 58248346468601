import ourServicesImg from "../../images/our-services.svg";
export default function servicesData() {
  return {
    title: "Чем мы можем помочь",
    desc: "Недобросовестные онлайн-казино, компании-кухни, пирамиды, за время работы наши специалисты сталкивались практически со всеми возможными видами мошенников. Какие бы аферисты не присвоили Ваши деньги, мы скорее всего уже имеем богатый опыт аналогичных случаев и сможем помочь быстро и эффективно вернуть Ваши средства. Чаще всего к нам обращатся по поводу:",
    img: ourServicesImg,
    list: [
      "брокеров сливащих депозиты клиентов",
      "схем Понци, финансовых пирамид под видом инвестиционных проектов",
      "различных видов неправомерного присвоения криптовалты",
      "проблем с банком в осуществлении chargeback",
    ],
  };
}
