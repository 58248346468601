import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import strategyData from "../data/strategyData";

const Strategy = ({ onBackDropClick }) => {
  const strats = strategyData();
  return (
    <section className="py-5 strategy" id="strategy">
      <Container className="pt-2 pb-5">
        <div>
          <h3 className="mb-5">{strats.title}</h3>
          <p>{strats.descr_P1}</p>
          <p>{strats.descr_P2}</p>
          <Row>
            {strats.list.map((e, i) => (
              <Col md="6" key={i} className="strategyItem">
                <div className="strategyItemTitle">
                  <div className="strategyItemNumb">{i + 1}</div>
                  <h5>{e.title}</h5>
                </div>
                <p>{e.desc}</p>
              </Col>
            ))}
          </Row>
        </div>

        <div className="text-left mt-3">
          <button
            onClick={() => {
              onBackDropClick();
            }}
            className="btn btn-primary"
          >
            Получить консультацию
          </button>
        </div>
      </Container>
    </section>
  );
};

export default Strategy;
