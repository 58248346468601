import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import slidesData from "../data/slidesData";

const Slider = ({onBackDropClick}) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const slides = slidesData();
  const renderSlides = slides.map((e, i) => {
    return (
      <Carousel.Item key={i}>
        <img
          className="d-block w-100 slide-img"
          src={e.src}
          alt={`slide${i + 1}`}
        />
        <Carousel.Caption>
          <div className="logo_inner">
            <img src={e.logo} alt="logo" className="logoImg" />
          </div>
          <h2 className="mb-4">{e.title}</h2>
          <p className="mb-5 slider-descr">{e.subtitle}</p>
          <button
            onClick={() => {
              onBackDropClick();
            }}
            className="btn btn-primary"
          >
            {e.btn}
          </button>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });

  return (
    <Carousel
      activeIndex={index}
      interval={3000}
      indicators={false}
      onSelect={handleSelect}
    >
      {renderSlides}
    </Carousel>
  );
};

export default Slider;
