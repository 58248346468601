import React from "react";
import LeadForm from "../../../Form";

import Modal from "./Modal";

const ContactFormModal = ({ isModalVisible, onBackDropClick }) => {
  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal onBackDropClick={onBackDropClick}>
      <div className="contactFormWrap">
        <div className="formTitle">
          <h2>Получить бесплатную консультацию</h2>
        </div>
        <LeadForm />
      </div>
    </Modal>
  );
};

export default ContactFormModal;
