import React, { useState } from "react";
import "./App.css";

import Menu from "./js/components/Menu.jsx";
import Slider from "./js/components/Slider";
import Services from "./js/components/Services.jsx";
import Benefits from "./js/components/Benefits.jsx";
import Strategy from "./js/components/Strategy.jsx";
import ReturnFunds from "./js/components/ReturnFunds.jsx";
import WhyWe from "./js/components/WhyWe.jsx";
import OurGoals from "./js/components/OurGoals.jsx";
import Testimonials from "./js/components/Testimonials.jsx";
import AboutUs from "./js/components/AboutUs.jsx";
import Map from "./js/components/Map";
import Footer from "./js/components/Footer";
import ContactFormModal from "./js/components/modal/ContactFormModal";
import WhatsAppIcon from './whatsapp/WhatsAppIcon';

function App() {
    const [isModalVisible, setModalVisible] = useState(false);
    const toggleModal = () => {
        setModalVisible((wasModalVisible) => !wasModalVisible);
    };
    return (
        <div className="App">
            <div className="slider" id="home">
                <Menu />
                <Slider onBackDropClick={toggleModal} />
            </div>
            <Services onBackDropClick={toggleModal} />
            <Benefits />
            <Strategy onBackDropClick={toggleModal} />
            <ReturnFunds />
            <WhyWe onBackDropClick={toggleModal} />
            <OurGoals />
            <Testimonials />
            <AboutUs />
            <Map />
            <Footer />

            <ContactFormModal
                isModalVisible={isModalVisible}
                onBackDropClick={toggleModal}
            />
            <WhatsAppIcon />
        </div>
    );
}

export default App;
