import team_img from '../../images/strategyNew.svg';
import getIcons from './strategyIcons';
export default function strategyData() {
  const icons = getIcons();
  return {
    title: "Наша стратегия",
    descr_P1: "Длительный опыт успешной борьбы с мошенниками и возврата средств клиентам позволил нам выработать эффективную стратегию действий для самых разных вариантов развития событий. Кроме того, годы работы позволили наше компании установвить прочные рабочие связи с необходимыми ведомствами и службами, а также финансовыми учреждениями как в стране, так и за ее пределами, что дает шанс на возврат средств даже из оффшоров.",
    descr_P2: "С точки зрения нашего клиента возврат денег проходит в четыре простых действия:",
    img: team_img,
    list: [
      {
        icon: icons[0],
        title: "Предварительная оценка",
        desc: "Эксперты компании разбиратся в Вашем случае. Мы не беремся за безнадежные дела.",
      },
      {
        icon: icons[1],
        title: "Подписание договора",
        desc: "Мы фиксируем в договоре условия сотрудничества, а Вы даете нам право представлять Ваши интересы",
      },
      {
        icon: icons[2],
        title: "Разбирательство",
        desc: "Мы используем все доступные средства начиная с досудебного давления и вплоть до судебного разбирательства для возврата Ваших денег",
      },
      {
        icon: icons[3],
        title: "Возврат средств",
        desc: "Вы получаете свои деньги назад. Только после этого мы получаем свое вознаграждение",
      },
    ],
  }
}