import React from "react";
import { Col, Row, Navbar } from "react-bootstrap";
import logo from "../../images/logoNew.svg";
import MenuInner from "./MenuInner";

const Footer = () => {
  return (
    <footer>
      <Navbar
        variant="dark"
        bg="dark"
        className="px-md-5 d-flex d-md-block flex-wrap flex-md-nowrap py-3"
      >
        <Row>
          <Col lg="2" md="4" className="text-center text-md-left mb-4">
            <Navbar.Brand href="#home">
              <img src={logo} alt="logo" className="logoImg" />
            </Navbar.Brand>
          </Col>
          <Col lg="4" md="8" className="text-light text-center text-md-left">
          </Col>
          <Col lg="6" md="8" >
            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar id="navbarScroll" className="justify-content-end"> */}
              <MenuInner isFooter={true} />
        {/*     </Navbar> */}
          </Col>
        </Row>
      </Navbar>
      <div className="copyright text-center bg-dark">
        (c) fenlonfinance.com, 2021
      </div>
    </footer>
  );
};

export default Footer;
