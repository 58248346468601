import React, { useState, useEffect } from "react";
import getWindowDimensions from "../../functions/getWinSizes";
import close from "../../../images/close.svg";

const Modal = ({ onBackDropClick, children }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const wd = windowDimensions.width;
  const widthCon = () => {
    return wd > 1299 ? { width: "60%" } : { width: "100%" };
  };
  return (
    <div className="modalOverlay" onClick={onBackDropClick}>
      <div
        className="modalContainer"
        onClick={(e) => e.stopPropagation()}
        style={widthCon()}
      >
        <img className="closeForm" onClick={onBackDropClick} src={close} alt="close contact form" />
        {children}
      </div>
    </div>
  );
};

export default Modal;
