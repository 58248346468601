import React from "react";
import LeadForm from "../../Form";
import { Col, Container, Row } from "react-bootstrap";
import aboutUsData from "../data/aboutUsData";

const AboutUs = () => {
  const aboutUs = aboutUsData();
  return (
    <section className="py-5 bg-dark aboutUs" id="aboutUs" >
      <Container className="pt-2 pb-5">
        <Row>
          <Col md="8">
            <h3>{aboutUs.title}</h3>
            <p>{aboutUs.desc}</p>
            <p>{aboutUs.desc2}</p>
          </Col>
          <Col md="4" className="aboutus-form">
            <h3 className="mb-4">Остались вопросы?</h3>
            <LeadForm />
            {/* <img src={aboutUsImg} className="about-img" alt="" /> */}
          </Col>

          <Col md="6" className="mt-4">
            <h3>{aboutUs.ourContact}</h3>
            <p>
              {aboutUs.desc3}
              <br />
              {aboutUs.desc4}
              <br />
              {aboutUs.desc5}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
