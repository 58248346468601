import React from "react";
import { Container } from "react-bootstrap";
import ourGoalsData from "../data/ourGoalsData";
import marker from "../../images/marker.svg"

const OurGoals = () => {
  const ourGoals = ourGoalsData();
  return (
    <section className="bg-dark py-5" id="ourGoals">
      <Container >
        <div className="ourGoals__inner">
          <h3 className="my-4">{ourGoals.title}</h3>
          <p>{ourGoals.subtitle}</p>
          <div className="ourGoalsList mt-5">
            
            {ourGoals.list.map((e, i) => (
              <div className={`ourGoalsItem ourGoalsItem-${i+1}`}  key={i}><div className="topImage"><img src={marker} alt=""/></div>{e}</div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OurGoals;
