import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import whyWeData from "../data/whyWeData";

const WhyWe = ({ onBackDropClick }) => {
  const whywe = whyWeData();
  return (
    <Container className="pt-2 pb-5 whywe" id="whywe">
      <Row>
        <Col md="5" className="our-advantages align-self-center">
          <img src={whywe.img} className="img-fluid" alt="why we" />
        </Col>

        <Col md="7" className="whyweTextBlock">
          <h3 className="my-5">{whywe.title}</h3>
          <ul className="whyWeList">
            {whywe.list.map((e, i) => (
              <li className="whyWeItem mb-4" key={i}>
                <div
                  className="whyWeItemImg"
                  dangerouslySetInnerHTML={{ __html: e.icon }}
                ></div>
                <div className="text pl-4">
                  <h5>{e.title}</h5>
                  <p>{e.text}</p>
                </div>
              </li>
            ))}
          </ul>
          <button
            onClick={() => {
              onBackDropClick();
            }}
            className="mt-4 btn btn-primary"
          >
            Получить консультацию
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyWe;
