import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import servicesData from "../data/servicesData";
import marker from "../../images/marker.svg";

const Services = ({ onBackDropClick }) => {
  const ourServices = servicesData();
  return (
    <Container className="pt-2 pb-5 our-services" id="our-services">
      <Row>
        <Col md="7">
          <h3 className="my-5">{ourServices.title}</h3>
          <p>{ourServices.desc}</p>
          <ul className="servicesList">
            {ourServices.list.map((e, i) => (
              <li key={i} className="servicesItem">
                <img src={marker} alt="" />
                {e}
              </li>
            ))}
          </ul>
          <button
            onClick={() => {
              onBackDropClick();
            }}
            className="mt-4 btn btn-primary"
          >
            Получить консультацию
          </button>
        </Col>
        <Col md="5" className="our-advantages align-self-center">
          <img
            src={ourServices.img}
            className="img-fluid"
            alt="how we can help"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
