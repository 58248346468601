import React from "react";

const Map = () => {
  return (
    <section id="map-section">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2245.655434413599!2d37.53361121573764!3d55.747115599898294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54bdd9911305f%3A0x4315ea51d6754fe9!2z0J_RgNC10YHQvdC10L3RgdC60LDRjyDQvdCw0LEuLCAxMCwg0JzQvtGB0LrQstCwLCDQoNC-0YHRgdC40Y8sIDEyMzMxNw!5e0!3m2!1sru!2sua!4v1631186060391!5m2!1sru!2sua"
        width="600"
        height="450"
        allowfullscreen=""
        loading="lazy"
        title="This is a unique title"
      ></iframe>
    </section>
  );
};

export default Map;
