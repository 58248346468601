import slideImg1 from '../../images/leader.jpg'
import slideImg2 from '../../images/team.jpg';
import slideImg3 from '../../images/slide3.jpg';
import logo from '../../images/logoNew.svg';

export default function slidesData() {
  return [
    {
      title: 'Квалифицированная помощь в возврате средств',
      subtitle: 'Защитим Ваши финансовые интересы в борьбе с нечестными биржами, брокерами, онлайн-казино и букмекерами. Без предоплат, взносов и скрытых комиссий.',
      btn: "Узнать больше",
      src: slideImg1,
      logo: logo
    },
    {
      title: 'Оплата только после возврата',
      subtitle: 'Оплата наших услуг осуществляется исключительно после того как Вы получите свои средства назад. Никакой предоплаты наших услуг.',
      btn: "Узнать больше",
      src: slideImg2,
      logo: logo
    },
    {
      title: 'Всю работу мы берем на себя',
      subtitle: 'Мы возьмем на себя все необходимые действия для возврата Ваших денег. Вам надо только подписать договор и предоставить нам необходимую информацию',
      btn: "Узнать больше",
      src: slideImg3,
      logo: logo
    },

  ];
}