import React from "react";
import { Navbar } from "react-bootstrap";
import MenuInner from "./MenuInner";


const Menu = () => {

  return (
    <Navbar
      variant="dark"
      bg="dark"
      expand="lg"
      className="bg-md-transparent"
      fixed="top"
      collapseOnSelect
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="navbarScroll"
        className="navbarScroll"
      >
        <MenuInner isFooter={false} />
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;
