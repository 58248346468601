export default function aboutUsData() {
  return {
    title: "О нас",
    desc: "В течение многих лет мы оказываем посильную правовую помощь в сфере финансов. Последние годы все чаще и чаще нам приходилось сталкиваться с различными мошенниками и необходимость возвращать деньги нашим клиентам. Теперь это наша специализация.",
    desc2:
      "Мы способны вернуть деньги как в России, так и за рубежом. Большой опыт, связи в государственных и финансовых структурах, а также тесное сотрудничество с передовыми разработчиками в сфере информационных технологий и блокчейн позволяют нам добиваться успеха даже в самых сложных случаях.",
    ourContact: "Наши контакты",
    desc3: "Москва-сити, деловой комплекс «Империя»",
    desc4: "Пресненская наб., 10, стр. 4",
    desc5: "Москва, Россия, 125039",
  };
}
