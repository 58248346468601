import customer1 from '../../images/customers/man1.jpg'
import customer3 from '../../images/customers/man2.jpg'
import customer2 from '../../images/customers/woman1.jpg'
export default function testimonialData() {
  return {
    title: "Отзывы о нас",
    desc: "Нам крайне важно мнение наших клиентов о нашей компании. Мы делаем все возможное, чтобы не только вернуть Вам деньги, но и сделать это максимально просто, быстро и необременительно.",
    list: [
      {
        img: customer1,
        author: "Дмитрий Тимохов",
        text: "Стыдно признаться, но я допустил ошибку и вложил достаточно крупную сумму в инвестиционный фонд без должной проверки. То ли загипнотизировали, то ли алчность взыграла, в общем достались мои денежки аферистам. Побежал жаловаться в органы, а там надо мной только посмеялись. Юридически все оформлено так, что не подкопаешься. К счасть обратился сюда. Подкупило то, что не надо ничего платить, пока деньги не вернутся. И, о чудо, уже через три с половиной недели мои деньги вернулись. Не бесплатно конечно, но я очень благодарен.",
      },
      {
        img: customer2,
        author: "Инесса Черткова",
        text: "Выражаю крайнюю признательность за возврат моей криптовалюты. Случай был непростой, я до сих пор не понимаю как это удалось но факт есть факт, мои деньги у меня. Особенно с учетом того, что я умудрилась совершить перевод не зная даже кому именно я делаю транзакцию. Еще раз спасибо.",
      },
      {
        img: customer3,
        author: "Денис Давыдов",
        text: "Мой опыт с трейдингом нельзя назвать удачным. Все закончилось сливом депозита и игнором со стороны брокера. Классическая история. Но зато у меня отличный опыт по возврату денег. Огромная благодарность всем специалистам возвращавшим мои кровные. Действительно быстро и просто, во всяком случае для меня.",
      },
    ],
  };
}