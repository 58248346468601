import whyWeImg from "../../images/whywe.svg";
import getIcons from "./whyWeIcon";
export default function whyWeData() {
  const icons = getIcons();
  return {
    title: "Почему мы",
    desc: "",
    img: whyWeImg,
    list: [
      {
        title: "Конфиденциальность",
        text: "Являясь нашим клиентом, Вы можете быть абсолютно уверены в том, что любая информация о Вас не будет разглашена. В том числе и государственным органам",
        icon: icons[0],
      },
      {
        title: "Простота",
        text: "Мы представляем интересы наших клиентов беря на себя абсолютно все действия, необходимые для возврата средств. Нашим клиентам достаточно лишь подписать договор и предоставить нам данные",
        icon: icons[1],
      },
      {
        title: "Результативность",
        text: "Уже в течение многих лет мы выигрываем более 90% дел за которые беремся. Сотни людей получили назад свои деньги с нашей помощью",
        icon: icons[2],
      },
      {
        title: "Оперативность",
        text: "Абсолютное большинство дел по возврату средств клиенту успешно заканчивается уже в течение первых тридцати дней",
        icon: icons[3],
      },
    ],
  };
}
