export default function ourGoalsData() {

  return {
    title: "Наша цель - возврат денег",
    subtitle: "Основным приоритетом нашей компании является возврат денег нашим клиентам. Именно поэтому мы используем все законные методы для возврата Ваших средств. В том числе как досудебное давление, так и мировое соглашение со оппонирующей стороной. Многие мошенники соглашаются добровольно вернуть деньги наших клиентов осознавая бесперспективность борьбы с нами. Это позволяет нам возвращать деньги наших клиентов еще быстрее.",
    list: [
      "Большинство наших клиентов ограничивается только обращением в правоохранительные органы, которые не имеют возможности, да и желания как-то помочь и вернуть деньги потерпевшим.",
      "Мы оказываем реально эффективное давление на мошенников уже на начальной стадии дела. При этом способны делать это также и вне национальной юрисдикции.",
      "Практика показывает, что чаще всего аферисты согласны вернуть деньги просто чтобы избежать громких разбирательств, привлечения внимания и судебных дел."
    ]

  }
}