import React from "react";
import { Nav } from "react-bootstrap";

const MenuInner = ({ isFooter }) => {
  return (
    <Nav >
      <Nav.Link href="#home">Главная</Nav.Link>
      <Nav.Link href="#our-services">Наши услуги</Nav.Link>
      <Nav.Link href="#benefits">Преимущества</Nav.Link>
      {isFooter ? null : <Nav.Link href="#strategy">Стратегия</Nav.Link>}
      <Nav.Link href="#return-funds">Криптовалюта</Nav.Link>
      <Nav.Link href="#whywe">Почему мы</Nav.Link>
      {isFooter ? null : <Nav.Link href="#ourGoals">Наши цели</Nav.Link>}
      {isFooter ? null : <Nav.Link href="#testimonials">Отзывы</Nav.Link>}
      {isFooter ? null : <Nav.Link href="#aboutUs">О нас</Nav.Link>}
    </Nav>
  );
};

export default MenuInner;
