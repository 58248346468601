import getIcons from "./iconarr";
export default function benefitsData() {
  const icons = getIcons();
  return {
    title: "FENLON FINANCE это",
    desc: "",
    after_text: "",
    list: [
      {
        title: "Успешность",
        icon: icons[0],
        desc: "Девять из десяти дел за которые мы беремся заканчиваются возвратом средств",
      },
      {
        title: "Легальность",
        icon: icons[1],
        desc: "Мы поможем вернуть Ваши деньги не выходя за рамки правового поля",
      },
      {
        title: "Транспарентность",
        icon: icons[2],
        desc: "Мы предоставим Вам всю информацию по Вашему делу. Вы будете знать что делается, когда и почему",
      },
      {
        title: "Добросовестность",
        icon: icons[3],
        desc: "В отличие от многих других, мы не работаем по предоплате и получаем вознаграждение только после возврата денег",
      },
    ],
  }
}
